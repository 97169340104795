import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import React from "react"
import { Target } from "../../utils"
import Info from "../shared/about/Info"
import HeadingM from "../shared/HeadingM"

const query = graphql`
  query {
    room: file(relativePath: { eq: "do/building.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 500, height: 500)
      }
    }
  }
`

const AboutInfo = () => {
  const data = useStaticQuery(query)
  const items = {
    name: "Do",
    ceo: "中村　佑也",
    openDate: "2024年4月",
    address: "〒378-0016\n群馬県沼田市清水町４２０８−１",
    openingHours: "​8:30 - 17:30",
    phone: "0278-25-4772",
    target: "do" as Target,
    image: getImage(data.room),
    mapLink: "https://goo.gl/maps/A5E3osnBPBSbgAhE6",
  }
  return (
    <>
      <HeadingM title="施設について" target="do" />
      <Info {...items} />
    </>
  )
}

export default AboutInfo
